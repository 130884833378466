import { Grid,
  Typography,
  IconButton,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import arrayMove from 'array-move';
import { Container, Draggable } from 'react-smooth-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InputFormField from '../../../components/forms/InputFormField';
import SelectFormField from '../../../components/forms/SelectFormField';

export default function FormFields(props) {
  const [fields, setFields] = useState(props?.fields || []);

  const onAdd = () => {
    let newState;
    if (!fields?.length) {
      newState = [];
    } else {
      newState = fields.map((e) => ({ ...e }));
    }
    const sequenceId = newState.length + 1;
    newState.push({ sequenceId, label: '', status: 'active', type: 'text', isRequired: false, hintText: null, defaultValue: null });
    setFields(newState);
    props.onChange && props.onChange(newState);
  };

  const onDelete = (index) => {
    let newState = fields.map((e) => ({ ...e }));
    newState.splice(index, 1);
    setFields(newState);
    newState = newState?.map((elem, index) => ({ ...elem, sequenceId: index + 1 }));
    props.onChange && props.onChange(newState);
  };

  const onChange = (index, field, value) => {
    const newState = fields.map((e) => ({ ...e }));
    newState[index] = { ...newState[index], [field]: value };
    setFields(newState);
    props.onChange && props.onChange(newState);
  };

  const onDrop = ({ removedIndex, addedIndex }) => {
    const newItems = fields.map((e) => ({ ...e }));
    let newState = arrayMove(newItems, removedIndex, addedIndex);
    newState = newState?.map((elem, index) => ({ ...elem, sequenceId: index + 1 }));
    newState = newState.sort((a, b) => ((a.sequenceId > b.sequenceId) ? 1 : -1)) || [];
    setFields(newState);
    props.onChange && props.onChange(newState);
  };

  useEffect(() => {
    !fields?.length && setFields(props.fields);
  }, [fields, props.fields]);

  return (
    <>
      <Typography variant="h6" style={{ marginTop: 30, marginBottom: 10 }}>
        Formularfelder
        <IconButton
          onClick={onAdd}
        >
          <AddCircleIcon color="primary" />
        </IconButton>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <List>
            <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
              {fields?.map(({ label, sequenceId, status, type, isRequired, hintText, defaultValue, params }, index) => (
                <Draggable key={sequenceId} style={{ marginBottom: '20px', borderBottom: '1px solid black' }}>
                  <ListItem>
                    <IconButton className="drag-handle">
                      <DragIndicatorIcon />
                    </IconButton>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} lg={4}>
                        <SelectFormField
                          label="Type"
                          name="type"
                          value={type || 'text'}
                          options={[
                            { label: 'Text', value: 'text' },
                            { label: 'Textbox', value: 'multilineText' },
                            { label: 'E-Mail', value: 'email' },
                            /* { label: 'Datum', value: 'date' }, */
                            { label: 'Nummer', value: 'number' },
                            { label: 'Url', value: 'url' },
                            { label: 'Checkbox', value: 'checkbox' },
                            { label: 'Anonym einreichen', value: 'anonymCheckbox' },
                            { label: 'Verstecktes Feld', value: 'hidden' },
                            { label: 'Bewertung', value: 'rating' },
                            { label: 'Emoji Bewertung', value: 'emojiRating' },
                            { label: 'Mehrfachauswahl', value: 'multiSelect' },
                          ]}
                          onChange={({ target }) => onChange(index, 'type', target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <InputFormField
                          label="Beschriftung"
                          name="label"
                          value={label}
                          required
                          inputProps={{
                            maxLength: 255,
                          }}
                          onChange={({ target }) => onChange(index, 'label', target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <InputFormField
                          label="Hinweistext"
                          name="hintText"
                          value={hintText}
                          inputProps={{
                            maxLength: 255,
                          }}
                          onChange={({ target }) => onChange(index, 'hintText', target.value)}
                        />
                      </Grid>
                      { !['checkbox', 'name', 'anonymCheckbox', 'rating', 'emojiRating', 'multiSelect'].includes(type) ? (
                        <Grid item xs={12} sm={6} lg={4}>
                          <InputFormField
                            label="Standardwert"
                            name="defaultValue"
                            value={defaultValue}
                            inputProps={{
                              maxLength: 255,
                            }}
                            onChange={({ target }) => onChange(index, 'defaultValue', target.value)}
                          />
                        </Grid>
                      ) : null}

                      { type === 'anonymCheckbox' ? (
                        <Grid item sm={12} lg={4}>
                          <SelectFormField
                            label="Standardwert"
                            name="defaultValue"
                            value={defaultValue || 'false'}
                            options={[
                              { label: 'Anonym einreichen', value: 'true' },
                              { label: 'Nicht anonym einreichen', value: 'false' },
                            ]}
                            onChange={({ target }) => onChange(index, 'defaultValue', target.value)}
                          />
                        </Grid>
                      ) : null}

                      <Grid item xs={12} sm={6} lg={4}>
                        <SelectFormField
                          label="Status"
                          name="status"
                          value={status || 'active'}
                          options={[
                            { label: 'Aktiv', value: 'active' },
                            { label: 'Inaktiv', value: 'inactive' },
                          ]}
                          onChange={({ target }) => onChange(index, 'status', target.value)}
                        />
                      </Grid>
                      { type !== 'anonymCheckbox' ? (
                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControlLabel
                            control={
                              (
                                <Checkbox
                                  checked={isRequired}
                                  onChange={({ target }) => onChange(index, 'isRequired', target.checked)}
                                />
                              )
                            }
                            label="Erforderlich"
                          />
                        </Grid>
                      ) : null}

                      <Grid item xs={12} sm={6} lg={4}>
                        <InputFormField
                          label="URL-Parameter"
                          name="queryParam"
                          value={params?.queryParam || ''}
                          inputProps={{
                            maxLength: 255,
                          }}
                          onChange={({ target }) => onChange(index, 'params', { ...params, queryParam: target.value })}
                        />
                      </Grid>

                      { type === 'multiSelect' ? (
                        <Grid item xs={12} sm={6} lg={4}>
                          <InputFormField
                            label="Auswahlmöglichkeiten"
                            name="options"
                            value={params?.options || ''}
                            placerholder="Auswahl 1,Auswahl 2"
                            onChange={({ target }) => onChange(index, 'params', { ...params, options: target.value })}
                            required
                          />
                        </Grid>
                      ) : null}

                    </Grid>
                    <IconButton
                      onClick={() => onDelete(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                </Draggable>
              ))}
            </Container>
          </List>
        </Grid>
      </Grid>
    </>
  );
}
